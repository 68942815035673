<template>
  <div class="section">
    <header-page>
      <span class="text-dark fw-bold-700 size14">Penerimaan Penjualan</span>
      <template v-slot:action>
        <div class="d-flex w-100 justify-content-end">
          <div>
            <FilterButton />
          </div>
        </div>
      </template>
    </header-page>

    <div class="table__container" id="table-sales">
      <div class="transaction__filter--container bg-white py-2">
        <div class="d-flex align-items-center search__export w-100">
          <div class="d-flex pl-2">
            <b-img
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
              alt="Icon-order-list-down"
            />
          </div>
          <div class="search__input w-100 px-2">
            <b-input-group>
              <b-form-input
                placeholder="Cari nama produk induk"
              />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>

      <vue-good-table
        max-height="80vh"
        :columns="columns"
        :rows="rows"
        :fixed-header="false"
        :sort-options="{
          enabled: false
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Common -->
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div
            class="d-flex justify-content-between align-items-center flex-wrap px-2 bg-white border"
            style="height:80px"
          >
            <div class="d-flex align-items-center mb-1 mt-1">
              <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap ">
                Tampilkan 1 ke {{ pageLength }} dari {{ props.total }} transaksi.
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    style="color: #6B6C7E"
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    style="color: #6B6C7E"
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import {
  BImg, BPagination, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import HeaderPage from '@/components/HeaderPage.vue'
import FilterButton from '@/components/Filter.vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  title() {
    return 'Penerimaan Penjualan'
  },
  components: {
    HeaderPage,
    BImg,
    BPagination,
    BFormSelect,
    FilterButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VueGoodTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'Tanggal',
          field: 'tanggal',
        },
        {
          label: 'No. Invoice/SPT',
          field: 'no_invoice_spt',
        },
        {
          label: 'Penerima',
          field: 'penerima',
        },
        {
          label: 'Tipe',
          field: 'tipe',
        },
        {
          label: 'Nilai',
          field: 'nilai',
        },
        {
          label: 'Metode Pembayaran',
          field: 'metode_pembayaran',
        },
      ],
      rows: [],
    }
  },
  created() {
    this.$http.get('/payout-sales')
      .then(res => { this.rows = res.data })
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#table-sales {
  .vgt-responsive {
    background-color: #ffffff;
    height: 68vh;
  }
  table {
      thead {
        tr {
          th {
            border-bottom: 1px solid #ebe9f1;
            background-color: transparent;
            border-top: none;
            padding: 1.5rem 1.5rem;
            color: #7A7F94;
            text-transform: capitalize;
            font-size: 14px;
            vertical-align: middle;
            text-align: start;
        }
      }
      tbody {
        tr {
          td {
            color: $dark;
            font-size: 14px;
            font-weight: 600;
            padding: 1.5rem 1.5rem;
            border-bottom: 1px solid #ebe9f1;
          }
        }
      }
    }
  }
}

</style>
